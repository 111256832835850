<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="">
          <div class="card card-custom">
            <div class="card-header py-3">
              <div class="card-title align-items-start flex-column">
                <h3 class="card-label font-weight-bolder text-dark">
                  Notes Reminder
                </h3>
              </div>
            </div>
          </div>
          <template>

          </template>
          <div class="card-body">
            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
              <v-row>
                <v-col cols="12" sm="6" md="3">
                  <v-select
                      label="Filter by follow up notes"
                      v-model="search.requires_follow_up"
                      :items="['Note', 'Followup notes']"
                      v-on:keyup.enter="__search"
                      @input="search.requires_follow_up = $event !== null ? $event : ''"
                      clearable
                      outlined
                      dense
                  ></v-select>
                </v-col>

                <v-col cols="12" sm="6" md="3">
                  <v-select
                      label="Filter notes"
                      v-model="search.filter_by"
                      :items="['Pending', 'Completed', 'Over Due']"
                      v-on:keyup.enter="__search"
                      @input="search.filter_by = $event !== null ? $event : ''"
                      clearable
                      outlined
                      dense
                  ></v-select>
                </v-col>

                <v-col cols="12" sm="6" md="6" class="text-right">
                  <v-btn
                      @click.prevent="__search"
                      class="mt-1 btn btn-primary"
                      :loading="loading"
                  >
                    <v-icon small  elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                    Search
                  </v-btn>
                </v-col>
              </v-row>
            </div>

            <v-row v-if="notes.length > 0">
              <v-col cols="6" v-for="(note, index) in notes" :key="index">
                <v-expansion-panels inset>
                  <v-expansion-panel
                  >
                    <v-expansion-panel-header style="background: #e3e3e3;">
                      {{ note.name }} - {{ note.created_at }}
                      <strong class="ml-4 badge badge-primary" style="max-width: 100px" v-if="note.priority != 'urgent'">
                        {{note.priority}}</strong>
                      <strong class="ml-4 badge badge-danger w-48" style="max-width: 100px" v-else>{{note.priority}} </strong>
                      <strong class="ml-4 badge badge-danger w-25" style="max-width: 25px">{{index + 1}}</strong>
                      <a @click.prevent="openApplicationDetail(note.application_id)" class="ml-4 text-warning text-h6">Ref no. #{{note.application_id}}</a>
                      <strong class="ml-4 w-25" :class="{'badge badge-danger': isFilterBy == 'Over Due', 'badge badge-success': isFilterBy == 'Completed'}"  style="max-width: 70px">{{ isFilterBy }}</strong>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-col cols="12" md="12">
                        <i class="fa fa-check cursor-pointer mt-3 mx-4 float-right text-primary" @click="noteMarked(note)" v-if="note.requires_follow_up"></i>
                        <i class="fa fa-plus cursor-pointer mt-3 float-right text-warning" @click="addNoteFollowup(note.id)" v-if="note.requires_follow_up"></i>
                        <i class="fa fa-trash-alt cursor-pointer mt-3 mx-4 float-right text-danger" @click="deleteNote(note.id)"></i>
                      </v-col>
                      <span v-html="note.description" class="text-justify"></span>
                      <v-row>
                        <v-col cols="12" md="4">
                          <span><b>Student Name: </b> <span class="badge badge-primary">{{note.student_name}}</span></span>
                        </v-col>

                        <v-col cols="12" md="4">
                          <span><b>Agent Name: </b> <span class="badge badge-primary">{{note.agent_name}}</span></span>
                        </v-col>
                        <v-col cols="12" md="4">
                          <span><b>Institution: </b> <span class="badge badge-primary">{{note.institution}}</span></span>
                        </v-col>
                        <v-col cols="12" md="4">
                          <span><b>Education Level: </b> <span class="badge badge-primary">{{note.education_level}}</span></span>
                        </v-col>
                        <v-col cols="12" md="4">
                          <span><b>Academic Program Session: </b> <span class="badge badge-primary">{{note.academic_session}}</span></span>
                        </v-col>
                        <v-col cols="12" md="4">
                          <span><b>Academic Program: </b> <span class="badge badge-primary">{{note.academic_program}}</span></span>
                        </v-col>

                        <v-col cols="12" md="4">
                          <span><b>Follow up required: </b> <span class="badge badge-primary">{{note.requires_follow_up ? 'Yes' : 'No'}}</span></span>
                        </v-col>
                        <v-col cols="12" md="4">
                          <span><b>Follow up date: </b> {{note.formatted_followup_required_date ? note.formatted_followup_required_date : 'NA'}}</span>
                        </v-col>
                        <v-col cols="12" md="4">
                          <span> <b>Added by: </b> {{note.addedBy ? note.addedBy : 'Agent'}}</span>
                        </v-col>
                      </v-row>
                      <v-row cols="12" v-if="note.note_attachments && note.note_attachments.length > 0">
                        <v-col cols="12">
                          <b>Attachments</b>
                        </v-col>
                        <v-col cols="12">
                          <span class="mx-1 d-flex align-items-center" v-for="(attachment, index) in note.note_attachments" :key="index">
                            <a v-if="attachment && attachment.file_path && attachment.file_type== 'image'" :href="attachment.file_path.real" target="_blank"><v-img class="my-2" :src="attachment.file_path.real" max-width="100px" max-height="100px"></v-img></a>
                            <a v-if="attachment && attachment.file_path && attachment.file_type== 'doc'" :href="attachment.file_path.real" target="_blank"><i class="mx-1 fa-solid fa-file-word"></i>{{attachment.name}}</a>
                            <a v-if="attachment && attachment.file_path && attachment.file_type== 'cvs'" :href="attachment.file_path.real" target="_blank"><i class="mx-1 fa-solid fa-file-cvs"></i>{{attachment.name}}</a>
                            <a v-if="attachment && attachment.file_path && attachment.file_type== 'pdf'" :href="attachment.file_path.real" target="_blank"><i class="mx-1 fa-solid fa-file-pdf"></i>{{attachment.name}}</a>
                            <a v-if="attachment && attachment.file_path && attachment.file_type== 'xls'" :href="attachment.file_path.real" target="_blank"><i class="mx-1 fa-solid fa-file-excel"></i>{{attachment.name}}</a>
                            <a v-if="attachment && attachment.url" :href="attachment.url" target="_blank"><i class="mx-1 fa-solid fa-link" small></i>{{attachment.name}} Link</a>
                          </span>
                        </v-col>
                      </v-row>
                      <v-row v-if="note.note_followups && note.note_followups.length > 0">
                        <v-col cols="12" class="text-left">
                          <template>
                            <v-timeline v-for="(followup, index) in note.note_followups" :key="index">
                              <v-timeline-item >
                                {{ followup.formatted_date }}
                                <i class="mx-2 cursor-pointer fas fa-trash" @click="deleteFollowup(followup.id)"></i><br/>
                                <b>Followed by: </b> <span class="ml-1 font-italic">{{ followup.addedBy ? followup.addedBy : 'N/A' }}<br/>
                              <p class="text-justify" v-html="followup.remarks"></p>
                              </span>
                              </v-timeline-item>
                            </v-timeline>
                          </template>
                        </v-col>
                      </v-row>

                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
            </v-row>
            <div v-else class="text-center card-body">
              <NotFound
                  :message="'Today no notes are available'"
              ></NotFound>
            </div>
          </div>

          <AddNoteFollowup ref="add-note-followup" @refresh="getTodayRemainderNotes"></AddNoteFollowup>
        </div>
      </div>
    </div>
  </v-app>
</template>

<script>

import NoteService from "@/services/note/NoteService";
import AddNoteFollowup from "./AddNoteFollowup";
import NoteFollowupService from "@/services/note/followup/NoteFollowupService";
import NotFound from "@/view/pages/view/components/NotFound";

const note = new NoteService();
const noteFollowup = new NoteFollowupService();
export default {
  name: "Notes",
  props: ['userId', 'application_id'],
  components: {NotFound, AddNoteFollowup},
  data() {
    return {
      isFilterBy: null,
      loading: false,
      notes: [],
      search: {
        filter_by: 'Pending',
        requires_follow_up: '',
      },
    }
  },
  mounted() {
    this.getTodayRemainderNotes();
  },
  methods: {
    openApplicationDetail(applicationId) {
      console.log(applicationId)
      this.$router.push({ name: 'application-detail', params: { id: applicationId } });
    },
    __search() {
      this.getTodayRemainderNotes()
    },

    addNoteFollowup(id) {
      this.$refs['add-note-followup'].addNoteFollowup(id);
    },

    getTodayRemainderNotes() {
      this.loading = true;
      note.getTodayRemainderNotes(this.search).then(response => {
        this.notes = response.data.data;
        if(this.search.filter_by == 'Over Due') {
          this.isFilterBy = 'Over Due';
        }

        if(this.search.filter_by == 'Completed') {
          this.isFilterBy = 'Completed';
        }

        if(this.search.filter_by == 'Pending') {
          this.isFilterBy = null;
        }
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      });
    },

    noteMarked(item) {
      this.$confirm({
        message: `Are you sure you have followup this ??`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            note.noteMarked(item.id, item).then((response) => {
              this.$snotify.success("Note updated");
              this.getTodayRemainderNotes();
            })
                .catch(() => {
                  this.$snotify.error("Oops something went wrong");
                });
          }
        },
      });

    },
    deleteNote(id) {
      this.$confirm({
        message: `Are you sure you want to delete this note? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            note
                .delete(id)
                .then((response) => {
                  this.getTodayRemainderNotes();
                  this.$snotify.success("Note deleted");
                })
                .catch((err) => {
                  this.$snotify.error("Oops something went wrong");
                });
          }
        },
      });
    },
    deleteFollowup(id) {
      this.$confirm({
        message: `Are you sure to delete follow up note? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            noteFollowup
                .delete(id)
                .then((response) => {
                  this.getTodayRemainderNotes();
                  this.$snotify.success("Follow up note deleted ");
                })
                .catch((err) => {
                  this.$snotify.error("Oops something went wrong");
                });
          }
        },
      });
    }
  }
}
</script>